<template>
    <div v-if="ads.length">
        <div v-for="ad in ads" :key="ad.id">
            <ads-image v-if="ad.type_name === 'Image'" :ads_data="ad" />
            <ads-html v-else-if="ad.type_name === 'HTML'" :ads_data="ad" />
        </div>
    </div>
</template>

<script>
import Ad from '@/models/Ad'

export default {
    props: {
        limit: {
            type: Number,
            default: 1
        }
    },

    components: {
        AdsHtml: () => import('@/components/ads/AdsHtml'),
        AdsImage: () => import('@/components/ads/AdsImage'),
    },

    data: () => ({
        isLoading: false,
        items: [],
    }),

    computed: {
        ads () {
            return this.items.slice(0, this.limit)
        }
    },

    mounted() {
        this.getAds()
    },

    methods: {
        async getAds() {
            this.isLoading = true

            try {
                this.items = await Ad.where('active', 1).orderBy('created_at').get()
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>
